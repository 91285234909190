.egm-albums-title {
  font-weight: 800;
  font-size: 20.8978px;
  line-height: 25px;
  color: #1d3557;
  margin: 20px 0;
  cursor: default;
  display: flex;
  justify-content: space-between;
}
.invite-ph-box {
  background-color: #dfebec;
  width: fit-content;
  padding: 6px 14px;
  border-radius: 50px;
  align-items: center;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  line-height: 12px;
  cursor: pointer;
  height: fit-content;
  font-weight: 700;
  margin-top: 7px;
}
.invite-ph-box img {
  margin-right: 6px;
}

#copyIconMobile svg {
  background-color: white;
}
.geo-email-details .title {
  color: #8b7f8f;
}

.egm-promo-banner-text {
  margin-bottom: 0;
}
.egm-albums-title img {
  margin-right: 10px;
}
.egm-albums-title span {
  display: inline-block;
  vertical-align: middle;
}
.egm-album-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 15px;
  /* background: #f3f3f3; */
  background: #fff;
  margin-bottom: 15px;
  border-radius: 6px;
  cursor: pointer;
}
.egm-album-btn {
  background: transparent;
  border: none;
}
.egm-album-btn img {
  height: 9px;
  display: block;
}
.egm-album-name {
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  color: #1d3557;
  margin-bottom: 5px;
}
.egm-album-count {
  font-size: 12.7709px;
  line-height: 16px;
  color: rgba(7, 36, 22, 0.5);
  margin: 0;
}
.egm-album-count span.egm-count {
  font-weight: 800;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #8b7f8f;
  /* margin-right: 15px; */
}
.egm-album-count span.egm-pgrapher {
  display: inline-block;
  vertical-align: bottom;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.egm-album-selected-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 15px 0;
  /* margin-bottom: 15px; */
  margin-bottom: 0;
  border-radius: 6px;
}
.egm-album-back-btn {
  padding: 0;
  background: transparent;
  margin-right: 12px;
  border: none;
}
.egm-album-selected-name {
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin: 0;
}

.dv-album-wrap {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}
/* popup styles */
.geo-img-popup {
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background: #fff;
  border-radius: 8px;

  position: relative;
}
.geo-img-popup .modal-dialog {
  margin: 0;
}
.geo-img-popup .modal-content {
  border: 0;
  /* max-width: 465px; */
  box-shadow: none;
}
.geo-img-popup .modal-dialog {
  max-width: 465px;
  width: 100%;
  margin: 0 auto;
  box-shadow: none;
}
.geo-img-popup .modal-header {
  padding: 10px;
  border: 0;
}
.geo-img-popup .mobile-header {
  padding-bottom: 10px;
}
.geo-img-popup + .modal-backdrop {
  background: #fff;
  opacity: 1;
}
.geo-img-carts {
  white-space: nowrap;
  display: flex;
  gap: 5px;
}
.geo-img-popup .mobile-header a.btn:last-child {
  margin: 0;
}
.geo-img-popup .dv-imgdetails-text p {
  padding-bottom: 10px;
  text-transform: none;
  font-size: 14px;
  line-height: 1;
  padding-top: 10px;
  color: #30053f;
  text-align: left;
}
.geo-img-popup .dv-imgdetails-text p a {
  color: #30053f;
  letter-spacing: 0;
}
.geo-img-popup .dv-imgdetails-text img {
  margin-right: 5px;
  display: inline-block;
  vertical-align: bottom;
}
#modal-mygallery.geo-img-popup .close {
  background: transparent;
}
#modal-mygallery.geo-img-popup .close-modal {
  /* padding-top: 10px; */
  padding-top: 4px;
  /* padding-right: 10px; */
  padding-right: 5px;
}
.geo-img-leftcont h3 {
  margin-bottom: -4px;
}
.geo-img-leftcont h3 strong {
  font-weight: 800;
}
.geo-img-leftcont p {
  opacity: 0.5;

  line-height: 1.1;
  margin-top: 7px;

  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.geo-img-popup .modal-footer-details {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
}
#modal-mygallery.geo-img-popup .modal-body,
#modal-mygalleryv.geo-img-popup .modal-body {
  background: #fff;
}
.geo-img-popup .dv-imgdetails span.align-helper {
  display: none;
}
.geo-img-popup .carousel-control.left span,
.geo-img-popup .carousel-control.right span {
  /* font-size: 14px;
  width: 28px;
  height: 28px;
  background: #30053f;
  line-height: 26px;
  border-radius: 50%; */
}
.geo-img-popup .carousel-control {
  opacity: 1;
}
.btn-add-cart,
.btn-rem-cart,
.btn-check-cart,
.btn-download-on-img {
  border-radius: 50%;
  border: 0;
  line-height: 38px;
  height: 40px;
  width: 40px;
  padding: 0 !important;
}
.ns .btn-rem-cart,
.btn-rem-cart {
  /* background: #cfcfcf; */
  border: 1px solid;
}
.btn-add-cart svg,
.btn-rem-cart svg {
  width: 19px;
  height: 19px;
}
.btn-add-cart svg {
  height: 23px;
}
.btn.btn-check-cart {
  margin-right: 0;
  color: #fff;
  line-height: 41px !important;
}
.btn-download-on-img {
  position: absolute;
  z-index: 999;
  right: 30px;
  bottom: -55px;
}
.modal-footer-actions {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 370px) {
  .geo-img-popup .modal-footer-details {
    padding-right: 25px;
  }
}
.egm-loader.gss-loader {
  padding: 15vh 0;
}
.egm-loader.gss-loader img {
  width: 45px;
}

.egm-nopics {
  text-align: center;
  margin: 20px auto;
}
.egm-notify-div {
  background: #ffffff !important;
  margin: -1px -14px;
}
.egm-color-notify-div {
  background-color: #e5e5e5;
  border-radius: 10px;
  display: flex;
  background-color: #e5e5e5;
  width: 100%;
  padding: 15px;
}
.egm-nopics.egm-noresults {
  padding: 0 20px;
}
.egm-nopics-title {
  font-weight: 800;
  color: #30053f;
  margin-top: 25px;
  margin-bottom: 4px;
}
.egm-nopics-para {
  color: #8b7f8f;
  font-size: 14px;
}
.egm-nopics .get-notified-input-div {
  margin: 10px 0 0;
}
.egm-nopics .get-notified-input-div input {
  background: #fff;
}

.egm-nopics .get-notified-input-div + .egm-nopics .get-notified-btn {
  position: absolute;
}

.egm-nopics .get-notified-btn {
  height: 40px;
  width: auto;
  padding: 0 20px;
  position: relative;
}
.egm-ht {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

@media (max-width: 991px) {
  .egm-wrap:has(.egm-nopics.egm-noresults) .egm-notify-div {
    margin-bottom: 0px;
  }
  .egm-nopics.egm-noresults {
    background-color: #fff;
    margin: 0px -14px;
    padding: 10px 20px 25px 20px;
    min-height: 50vh;
  }
  .egm-nopics .egm-password {
    background-color: #eff5f6;
    padding: 20px;
    border-radius: 10px;
  }
  .mobile-photos-ht {
    min-height: 50vh;
  }
  .invite-ph-box {
    margin-top: 0px;
  }
}

@media (max-width: 991px) and (min-width: 600px) {
  .egm-nopics .get-notified-btn {
    right: 4px;
    top: 4px;
  }
}
.get-notified-input-div span.error {
  display: block;
  margin-top: 7px;
  line-height: 1.4;
}
.egm-nopics .get-notified-btn:focus,
.egm-nopics .get-notified-btn:active {
  outline: 0;
}
.egm-noresults .egm-nopics-title {
  margin-bottom: 20px;
}
.clear-filters-btn {
  padding: 5px 15px;
  background: #fff;
  color: rgb(29 53 87 / 60%);
  border: 0;
  border-radius: 45px;
}
.clear-filters-btn img {
  opacity: 0.6;
}

svg,
.dv-album-photos svg {
  display: inline-block;
  vertical-align: middle;
  /* position: relative; */
  /* top: -2px; */
}
.ReactGridGallery svg {
  filter: drop-shadow(0 0 4px rgb(0 0 0 / 0.4));
}

.egm-password .egm-nopics-title {
  margin-top: 15px;
  margin-bottom: 7px;
}
.egm-password .error {
  color: red;
}
.egm-password a {
  color: #000;
}

.prepaid-strike {
  text-decoration: line-through;
}

.egm-lightbox.modal button.close {
  position: absolute;
  right: 15px;
  top: 5px;
  font-weight: 400;
  box-shadow: none;
}

.geo-img-leftcont:has(.geo-lt__photoname) p {
  margin-top: 3px;
}

.geo-lt__photoname {
  margin-top: 4px;
  color: #8b7f8f;
}
