#get-notified-dialog {
  display: block;
}
.get-notified-dialog-content {
  background: transparent;
  width: 380px;
  position: relative;
  text-align: center;
  margin: 8% auto 5%;
}
.get-notified-content-div {
  border-radius: 10px;
  background: #fff;
  width: 100%;
  padding-bottom: 40px;
}
.get-notified-icon-div {
  position: relative;
  width: 62px;
  height: 62px;
  top: -31px;
  background: #ffffff;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  margin: 0 auto;
  padding-top: 11px;
}
.get-notified-header {
  font-weight: 800;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  margin: -13px 0 14px;
}
.get-notified-p {
  width: 310px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.470588px;
  color: rgba(7, 36, 22, 0.5);
  margin: 0 auto 25px;
}
.get-notified-events {
  overflow: auto;
  max-height: 200px;
}
.get-notified-ev-card {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #cfcfcf;
  padding: 25px 0;
  margin: 0 25px;
}
.get-notified-ev-card:last-child {
  border-bottom: none;
}
.get-notified-ev-date-badge {
  min-width: 51px;
  height: 51px;
  border-radius: 50%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
  margin-right: 10px;
}
.get-notified-ev-date-p1 {
  font-size: 18px;
  margin: 3px 0 0 0;
  line-height: 22px;
}
.get-notified-ev-date-p2 {
  font-size: 9px;
  margin: 0;
  line-height: 11px;
}
.get-notified-ev-date-p3 {
  font-size: 9px;
  margin: 0;
  line-height: 11px;
}
.get-notified-ev-p1 {
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin: 2px 0;
  text-align: left;
}
.get-notified-ev-p2 {
  font-size: 14px;
  line-height: 17px;
  color: rgba(7, 36, 22, 0.5);
  margin-bottom: 0px;
  text-align: left;
}
.get-notified-input-div {
  margin: 10px 25px 0;
  position: relative;
}
.get-notified-input-div input {
  background: #eaeaea;
  border-radius: 34px;
  width: 100%;
  height: 47px;
  border: none;
  padding: 0 20px;
}
.get-notified-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
  height: 47px;
  border: none;
  border-radius: 25px;
  color: #fff;
  font-size: 14px;
  font-weight: 800;
}

.get-notified-cancel-btn {
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  background: transparent;
  border: none;
  margin-top: 20px;
}

.get-notified-close-icon {
  float: right;
  cursor: pointer;
  margin: 12px;
}

@media screen and (max-width: 768px) {
  .get-notified-dialog-content {
    margin: 20% auto 5%;
  }
}

@media screen and (max-width: 599px) {
  .get-notified-dialog-content {
    width: 90%;
    margin: 15% auto 3%;
  }
  .get-notified-p {
    width: 280px;
  }
  .get-notified-btn {
    position: relative;
    top: 10px;
  }
}
