/* .egm-header-container { */
.egm-head-wrapper {
  background: #fff;
  margin: -20px -15px 0;
  /* margin-top: -35px; */ /* keeping this in case any issues arise */
  padding: 15px 15px 0;
}
.egm-wrap .embed-branding {
  width: 115px;
  height: 40px;
  background: url(../images/powered-by-geo.svg) center no-repeat;
  background-size: contain;
}
.egm-wrap .embed-branding * {
  display: none;
}
.egm-wrap .checkout-text {
  display: none;
}
.egm-wrap .dv-embed-header .embed-checkout .checkout .fa {
  margin-right: 0;
  padding-left: 4px;
}
.egm-header-div {
  background: linear-gradient(286.64deg, #9b51e0 26.02%, #901ec0 99.21%);
  border-radius: 14px;
  padding: 17px;
}
.egm-panel-div {
  background: transparent;
  margin-bottom: 0;
  box-shadow: none;
}
.egm-header-row {
  display: flex;
  flex-direction: row;
  background: transparent !important;
  padding: 0 !important;
}
@media (max-width: 991px) and (min-width: 768px) {
  .egm-header-row {
    padding-right: 15px !important;
  }
}
.egm-header-btn-div {
  width: 25px;
  margin: 10px 10px 0 0;
}
.egm-header-btn-div.close-btn {
  margin: 0;
  padding: 0;
  width: 21px;
}

.egm-header-container .panel-default > .panel-heading a {
  letter-spacing: 0;
  display: inline-block;
  /* overflow: hidden; */
  /* white-space: nowrap; */
}
.egm-sub-details {
  color: #8b7f8f !important;
  font-weight: 400;
}
.egm-header-container .panel-default > .panel-heading span {
  color: black;
}

.egm-header-container .panel-default > .panel-heading .sub-heading span {
  color: #8b7f8f;
}
.egm-header-btn-div button {
  width: 25px;
  height: 25px;
  padding: 0;
  background: transparent;
  border: none;
  outline: 0;
}
.egm-header-btn-div + div {
  width: 86%;
}
.egm-header-container {
  padding-bottom: 12px;
  border-bottom: 1px solid #d0d0d0;
}

.panel-default > .panel-heading a:after {
  position: relative;
  display: inline-block;
  line-height: 0.5;
  margin-left: 5px;
  top: 0;
  right: auto;
}

.panel-default > .panel-heading .egm-header-name a:after {
  content: "\f106" !important;
  position: relative;
  /* display: inline-block; */
  line-height: 0.5;
  margin-left: 5px;
  top: 0;
  /* right: auto; */

  transition: all 0.2s ease-in;
}
.panel-default > .panel-heading .egm-header-name a.collapsed:after {
  transform: rotate(180deg);
  top: 1px;
}
.egm-header-name {
  margin: 0;
  overflow: hidden;
  font-size: 18px;
  font-weight: 600;
}
.egm-arrow {
  margin-left: 8px;
  transition: transform 0.2s ease-in;
}
.egm-arrow.arrow-up {
  transform: rotate(-180deg);
}
.egm-header-date {
  font-weight: 800;
  font-size: 12.7709px;
  line-height: 16px;
  letter-spacing: 1.16099px;
  text-transform: uppercase;
  opacity: 0.75;
  margin-bottom: 5px;
  cursor: default;
}
.egm-header-linked {
  margin-bottom: 0;
  margin-top: 5px;
  text-decoration: underline;
  color: #259fa5;
}
.egm-linked-single {
  text-align: left;
}
.egm-linked-single:last-child hr {
  display: none;
}
.egm-linked-name {
  font-weight: 800;
  display: block;
  margin-bottom: 5px;
}
.egm-linked-date {
  font-weight: 800;
  color: #30053f;
}
.egm-linked-location {
  color: #30053f;
}
.egm-linked-count {
  margin-top: 5px;
  color: #8b7f8f;
}

.egm-location-row {
  display: flex;
  flex-direction: row;
  margin-bottom: -15px;
  /* margin-left: 25px; */
  margin-left: 20px;
}
.egm-location-row img {
  margin-top: 7px;
  margin-right: 12px;
}

/* header styles when customer prepaid */
.egm-header-div2 {
  background: transparent;
  padding: 0;
}
.scroll-offers {
  display: flex;
  width: 100%;
  overflow-x: auto;
  margin-top: 5px;
  padding: 9px 0px;
}
/* prepaid section */
.egm-prepaid-wrap {
  margin: 0px -15px;
  padding: 1px 15px;
  height: 100%;
}
.egm-prepaid-div {
  border-radius: 12px;
  padding: 15px 15px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.egm-multi-wrap .egm-prepaid-div {
  padding-bottom: 15px;
}
.egm-multi-wrap hr {
  margin: 5px 0;
  border-color: #000;
  opacity: 0.2;
}
.egm-multi-total {
  margin-top: 10px;
}
.egm-prepaid-info,
span.promotion {
  font-size: 16.2539px;
  line-height: 20px;
  margin-top: 6px;
  margin-bottom: 10px;
  display: block;
}
.display-list-item {
  display: list-item;
  margin-left: 18px;
}
.egm-one-sentence .display-list-item {
  display: block;
  margin-left: 0;
}
.egm-prepaid-btn {
  width: fit-content;
  height: 35px;
  padding: 0 17px;
  font-weight: 800;
  font-size: 13.9319px;
  line-height: 17px;
  border-radius: 38px;
  border: none;
}

.egm-prepaid-wrap .prepaid-customer-welcome {
  font-size: 14px;
  line-height: 17px;
  padding: 20px 10px;
  border-bottom: 1px solid #ededed;
  background-color: white;
}
.egm-prepaid-wrap .prepaid-reauth {
  /* color: #34b2a7; */
  font-weight: 800;
  cursor: pointer;
  float: right;
}
.egm-prepaid-wrap .prepaid-customer-limit-welcome {
  /* background: linear-gradient(49.51deg, #9b51e0 26.02%, #901ec0 99.21%); */
  /* border-radius: 5px 5px 0 0; */
  /* color: white; */
  font-size: 14px;
  line-height: 18px;
  /* padding: 15px; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.egm-prepaid-wrap .count-badge {
  width: 45px;
  height: 45px;
  background: #101318;
  border-radius: 50%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  color: white;
  padding-top: 10px;
}
.egm-prepaid-wrap .count-badge-p1 {
  font-size: 18px;
  display: block;
  text-align: center;
  line-height: 1;
}
.egm-prepaid-wrap .count-badge-p1 .sub-count {
  font-size: 9px;
}
.egm-prepaid-wrap .count-badge-p2 {
  font-size: 9px;
  line-height: 11px;
  opacity: 0.4;
  display: block;
}
.egm-prepaid-wrap .prepaid-customer-cart-badge {
  background: white;
  color: #8b7f8f;
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  border-radius: 30px;
  padding: 8px 12px;
  cursor: pointer;
}
.egm-prepaid-wrap .cart-icon {
  float: left;
  height: 14px;
}
.egm-prepaid-wrap .cart-count {
  background: #30053f;
  border-radius: 30px;
  color: white;
  font-size: 11px;
  padding: 1px 8px;
  margin-left: 5px;
  display: inline-block;
  text-align: center;
}
.egm-prepaid-wrap .cart-amount {
  margin-left: 8px;
  font-size: 16px;
  float: right;
}
.egm-prepaid-wrap .cart-badge-container {
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.egm-prepaid-wrap .prepaid-customer-main {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.egm-one-sentence .prepaid-customer-main {
  flex-wrap: nowrap;
}
.prepaid-full-row {
  width: 100%;
  margin-top: 10px;
}
.prepaid-full-row div {
  display: list-item;
  margin-left: 19px;
  margin-bottom: 4px;
}
.egm-prepaid-wrap .prepaid-customer-content {
  display: inline-block;
  margin-top: 3px;
}
.egm-prepaid-wrap .prepaid-customer-content p {
  margin-bottom: 0px;
}
.egm-prepaid-wrap .prepaid-reauth {
  opacity: 0.85;
  cursor: pointer;
}
.cart-badge-container .dropdown-menu {
  border: 0;
}
.cart-badge-container .dropdown-menu > li > a {
  padding: 7px 20px;
}

.egm-ph-uploading-div {
  border-radius: 5px;
  padding: 14px 18px;
  margin: 14px 0px 24px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: max-height 500ms;
}
/* .dv-event-no-albums-banner {
  overflow: hidden;
  position: relative;
  max-height: 800px;
  transition: max-height 500ms;
} */
.egm-ph-uploading-div.collapsed {
  max-height: 0;
  display: none;
}
.egm-ph-uploading-info-div {
  flex: 1 0 0;
  margin-left: 15px;
  cursor: default;
}
.egm-ph-uploading-info {
  max-width: 116px;
  font-weight: 800;
  font-size: 13px;
  line-height: 17px;
  margin: 0;
}
.egm-ph-uploading-btn {
  width: 84px;
  height: 30px;
  border-radius: 38.3127px;
  border: none;
  font-size: 13px;
  font-weight: 800;
  margin-right: 14px;
}
ul.offer-list {
  margin-left: -25px;
  list-style-type: disc;
}

.egm-offer-text {
  margin-top: -14px;
  margin-bottom: 7px;
}
.egm-offer-text span:first-child {
  font-weight: 800;
  margin-right: 4px;
  font-size: 11px;
}
.egm-offer-text span:nth-child(2) {
  font-weight: 800;
  opacity: 0.6;
  font-size: 12px;
}
.egm-prepaid-btn.banner-btn-with-selfie {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 17px;
  width: fit-content;
}
.banner-btn-with-selfie img {
  margin-right: 10px;
}

.egm-notify-banner {
  margin-top: -5px;
  margin-bottom: 15px;
}

.mobile-offer {
  margin: 0px -15px 0;
  padding: 8px 15px 0;
  background-color: white;
}

@media (max-width: 600px) {
  .embedded .geo.wrap {
    width: 100%;
    overflow-x: hidden;
  }
}
