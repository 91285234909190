#share-gallery-modal-dialog {
  display: block;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.share-gallery-modal-dialog-content {
  background: transparent;
  width: 383px;
  position: relative;
  text-align: center;
  margin: 85px auto 30px;
}
.share-gallery-modal-content-div {
  border-radius: 10px;
  background: #fff;
  width: 100%;
  padding-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
}
.share-gallery-msg,
p.share-gallery-msg {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: #30053f;
  padding-top: 29px;
  width: 250px;
  margin: 0 auto;
}
.share-gallery-title {
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  background: transparent;
  margin: 20px 0;
}
.social-gallery-links {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ghost-btn {
  color: #ff0072;
  background-color: #fff;
  border: 1px solid #ff0072;
  border-radius: 35px;
  width: fit-content;
  padding: 7px 10px;
  margin: 10px;
  cursor: pointer;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.ghost-btn:hover {
  color: #ff0072;
}
.share-gallery-url {
  background: rgba(48, 5, 63, 0.07);
  border-radius: 34px;
  max-width: 306px;
  margin: 20px auto;
  overflow: hidden;
  height: 36px;
  display: flex;
  align-items: center;
}
.share-gallery-url span {
  display: block;
  padding: 5px 24px;
  /* text-overflow: ellipsis; */
  overflow: hidden;
  white-space: nowrap;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.share-gallery-url span::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
}

.share-gallery-modal-done-btn {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #8b7f8f;
  mix-blend-mode: normal;
  opacity: 0.8;
  cursor: pointer;
}
.gallery-close-icon {
  padding-top: 10px;
  float: right;
  cursor: pointer;
}
.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block;
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em 0.2em;
}

.resp-sharing-button {
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: top;
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle;
}

.resp-sharing-button__icon {
  stroke: #fff;
  fill: none;
}
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
}

.resp-sharing-button--twitter:hover {
  background-color: #2795e9;
}

.resp-sharing-button--pinterest {
  background-color: #bd081c;
}

.resp-sharing-button--pinterest:hover {
  background-color: #8c0615;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
}

.resp-sharing-button--facebook:hover {
  background-color: #2d4373;
}

.resp-sharing-button--tumblr {
  background-color: #35465c;
}

.resp-sharing-button--tumblr:hover {
  background-color: #222d3c;
}

.resp-sharing-button--reddit {
  background-color: #5f99cf;
}

.resp-sharing-button--reddit:hover {
  background-color: #3a80c1;
}

.resp-sharing-button--google {
  background-color: #dd4b39;
}

.resp-sharing-button--google:hover {
  background-color: #c23321;
}

.resp-sharing-button--linkedin {
  background-color: #0077b5;
}

.resp-sharing-button--linkedin:hover {
  background-color: #046293;
}

.resp-sharing-button--email {
  background-color: #777;
}

.resp-sharing-button--email:hover {
  background-color: #5e5e5e;
}

.resp-sharing-button--xing {
  background-color: #1a7576;
}

.resp-sharing-button--xing:hover {
  background-color: #114c4c;
}

.resp-sharing-button--whatsapp {
  background-color: #25d366;
}

.resp-sharing-button--whatsapp:hover {
  background-color: #1da851;
}

.resp-sharing-button--hackernews {
  background-color: #ff6600;
}
.resp-sharing-button--hackernews:hover,
.resp-sharing-button--hackernews:focus {
  background-color: #fb6200;
}

.resp-sharing-button--vk {
  background-color: #507299;
}

.resp-sharing-button--vk:hover {
  background-color: #43648c;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9;
}

.resp-sharing-button--email {
  background-color: #777777;
  border-color: #777777;
}

.resp-sharing-button--email:hover,
.resp-sharing-button--email:active {
  background-color: #5e5e5e;
  border-color: #5e5e5e;
}

.resp-sharing-button--whatsapp {
  background-color: #25d366;
  border-color: #25d366;
}

.resp-sharing-button--whatsapp:hover,
.resp-sharing-button--whatsapp:active {
  background-color: #1da851;
  border-color: #1da851;
}

@media screen and (max-width: 768px) {
  .share-gallery-modal-dialog-content {
    margin: 20% auto 5%;
  }
}

@media screen and (max-width: 599px) {
  .share-gallery-modal-dialog-content {
    width: 90%;
    margin: 15% auto 3%;
  }
  .social-gallery-links {
    display: block;
  }
  .ghost-btn {
    height: auto;
    margin-bottom: 15px;
    display: inline-block;
  }
}