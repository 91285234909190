.geo-privacy-modal .labelset-radio {
  padding: 0px;
  display: flex;
  padding: 16px;
  background-color: #fcf5fe;
  width: 100%;
}
.geo-privacy-modal .labelset-text {
  margin-left: 36px;
  color: #8b7f8f;
  line-height: 1.4;
  text-align: left;
  text-wrap: pretty;
}
.geo-privacy-btn {
  font-size: 14px;
  font-weight: 700;
  color: #259fa5;
  border: 1px solid #259fa5;
  background: #fff;
  padding: 12px 15px;
  border-radius: 36px;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  font-family: inherit;
}
.geo-privacy-btn:hover {
  background-color: #259fa5;
  color: white;
}

strong {
  border-bottom: none;
}
.geo-privacy-modal {
  max-width: 360px;
  height: fit-content;
  padding: 0 !important;
}
.geo-privacy-modal .modal-header {
  padding-bottom: 0;
}
.geo-privacy-modal .modal-body {
  padding-left: 28px;
  padding-right: 28px;
}
.geo-privacy-modal .labelset-radio .labelset-title {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: #30053f;
}
.confirm-close {
  right: 13px !important;
  top: 6px !important;
}
/* .ns-modal .close {
  color: #000;
} */
/* @media (max-width: 767px) {
  .geo-privacy-modal .modal-content {
    padding-bottom: 50px !important;
  }
} */
@media (max-width: 500px) {
  /* .geo-privacy-modal {
    max-width: 360px;
  } */
}
@media (max-width: 400px) {
  .geo-privacy-modal .modal-body {
    padding-left: 12px;
    padding-right: 12px;
  }
  .geo-btn--caps,
  button.geo-btn--caps,
  .geo-sub-header,
  .geo-muted-info {
    font-size: 13px;
  }
}
