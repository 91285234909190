.geo-gallery-sidebar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 300px;
  z-index: 999;
  border-right: 1px solid #cfcfcf;
  overflow-y: auto;
  height: 100%;
  padding: 10px 20px;
  padding-top: 74px;
  background-color: white;
  -webkit-box-shadow: 39px 0px 32px -13px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 39px 0px 32px -13px rgba(0, 0, 0, 0.09);
  box-shadow: 39px 0px 32px -13px rgba(0, 0, 0, 0.09);
}
.geo-manager-header {
  font-size: 20px;
  height: 45px;
  font: Proxima Nova;
  border-bottom: 1px solid #cfcfcf;
  margin-top: 10px;
}
.geo-manager-header span {
  font-weight: 600;
  color: #383838;
}
.geo-gallery-sidebar ul {
  cursor: pointer;
  list-style: none;
  padding-left: 0px;
  position: relative;
  margin: 0;
}
.album-name-icon {
  width: 100%;
  padding: 5px 0px 5px 10px;
  display: flex;
}
.album-name-icon .album-name {
  margin-left: 3px;
}

.album-border {
  padding: 2px 0px;
  cursor: pointer;
}

.album-border {
  position: relative;
  padding-left: 25px;
  display: flex;
  align-items: center;
}
.album-border:before {
  content: "";
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  height: 1px;
  width: 15px;
  background-color: #cfcfcf;
}
.album-border:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #cfcfcf;
}

ul > li.album-border:last-child:after {
  bottom: 50%;
}
.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #eff5f6;
  padding-bottom: 10px;
}

.create-album-btn {
  border: none;
  padding: 3px 13px;
  background-color: #eff5f6;
  border-radius: 30px;
  color: #259fa5;
  display: flex;
  align-items: center;
  font-size: 14px;
}
a.create-album-btn:hover {
  color: #259fa5;
}
.toggle-sidebar-btn {
  background-color: #eff5f6;
  cursor: pointer;
  padding: 15px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  font-size: 14px;
  font-weight: 700;
  color: #30053f;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
}
.toggle-sidebar-btn .sub-span {
  letter-spacing: 1px;
  color: #8b7f8f;
  text-transform: uppercase;
  line-height: 15px;
  font-size: 13px;
}
.album-name-icon {
  width: 100%;
  padding: 5px 0px 2px 10px;
  display: flex;
}
.album-name-icon.selected {
  background-color: #eff5f6;
  border-radius: 3px;
}

.album-name {
  width: 196px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.event-item a,
.event-item a:hover,
.album-name-icon a,
.album-name-icon a:hover {
  color: #30053f;
  display: flex;
}
.event-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px 0px;
  margin: -1px 0px;
}
.event-name {
  display: inline-block;
  width: 206px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.event-item.selected {
  background-color: #eff5f6;
  border-radius: 3px;
  margin-left: -8px;
  padding-left: 8px;
}
.event-group:hover,
.album-name-icon:hover {
  opacity: 0.7;
}
.event-group:active,
.album-name-icon:active {
  opacity: 1;
}
.arrow-spacing {
  display: inline-block;
  cursor: pointer;
  width: 22px;
}

.arrow-spacing img {
  pointer-events: none;
}
.file-spacing {
  width: 16px;
  margin-right: 10px;
}
.event-group {
  display: flex;
  width: 100%;
}
.load-more-container {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}
.loading-spinner {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 992px) {
  .content {
    max-height: 400px;
    overflow-y: scroll;
  }
  .content hr {
    border-top: 1px solid #cfcfcf;
    margin-top: -8px;
    margin-top: 0px;
    margin-bottom: 6px;
  }
  .event-div ul {
    padding: 0px 1px;
  }
  .event-group {
    width: 90%;
  }
  .event-item a,
  .album-name-icon a {
    width: 100%;
  }
  .event-item.selected {
    background-color: #dfebec;
  }
  .event-name,
  .album-name {
    width: auto;
  }
  .create-album-btn {
    background-color: #deebec;
  }
}
