.dark-txt {
  color: #000000 !important;
}
.light-txt {
  color: #ffffff !important;
}
.border-line {
  border-top: 1px solid #cfcfcf;
  margin-top: 10px;
}
.geo-em__btn {
  margin-bottom: 0px;
  margin-top: 0px;
}
.curation-actions {
  display: flex;
  align-items: center;
}
.geo-visibility-stamp {
  position: absolute;
  left: 8px;
  top: 8px;
  z-index: 40;
}
.geo-visibility-stamp img {
  width: 23px;
}
.geo-email-support-div {
  background: #fff;
  margin: 0 -15px;
  padding: 7px 15px 7px 15px;
}
.contact-refer-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contact-refer-div .invite-ph-box {
  margin-right: 0px;
}
.egm-nopics .get-notified-input-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.egm-nopics .get-notified-input-div input {
  background: #fcf5fe;
  margin-bottom: 6px;
  width: 350px;
}
.btn-link {
  color: #8b7f8f;
}
@media (max-width: 500px) {
  .egm-nopics .get-notified-input-div input {
    background: #fcf5fe;
    margin-bottom: 6px;
    width: 250px;
  }
}
