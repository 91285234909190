#album-tag-modal-dialog {
  display: block;
}
.album-tag-modal-dialog-content {
  background: transparent;
  width: 383px;
  position: relative;
  text-align: center;
  margin: 8% auto 5%;
}
.album-tag-modal-content-div {
  border-radius: 10px;
  background: #fff;
  width: 100%;
  padding-bottom: 40px;
  padding: 24px;
}
.gallery-close-icon {
  top: -32px;
  right: 10px;
  position: absolute;
  color: white;
  font-size: 17px;
}

.album-tag-modal-dialog-content {
  background: transparent;
  width: 383px;
  position: relative;
  text-align: center;
  margin: 52px auto;
  font-family: "proxima-nova";
}
.album-modal-head {
  font-weight: 800;
  font-size: 17px;
  line-height: 35px;
  letter-spacing: 0px;
  text-align: center;
  color: #30063f;
}
.album-modal-sub {
  font-size: 14px;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #8b7f8f;
}
.album-name-div {
  background-color: #eff5f6;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
}
.head-2-font {
  font-size: 14px;
  line-height: 18px;
}
.sub-2-font {
  font-weight: 200;
}
.react-tagsinput {
  display: flex;
}
.mutli-select-input {
  margin: 15px 0px;
  min-height: 180px;
}

.react-tagsinput span {
  display: flex;
  flex-wrap: wrap;
}
.react-tagsinput-tag {
  background-color: #f1f1f1 !important;
  border: 1px solid #e5e5e5 !important;
  color: #757575 !important;
  border-radius: 5px !important;
}
.react-tagsinput-remove {
  color: #fb3737;
  margin-left: 5px;
}
.option-tags {
  color: #8b7f8f;
  width: fit-content;
  border: 1px solid #8b7f8f;
  padding: 4px;
  display: inline-block;
  border-radius: 4px;
  margin: 5px;
}
.tags-div {
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
}
.suggestions-div {
  border-top: 1px solid #cfcfcf;
}
.sugg-head {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: 700;
  margin: 15px 0px;
  font-size: 13px;
  letter-spacing: 0.05em;
}
.sugg-head .title {
  color: #8b7f8f;
}
.sugg-head .add-tag-title {
  color: #259fa5;
}
.tag-box {
  padding: 2px 4px;
  display: flex;
  border: 1px solid #d9d9d9;
  width: fit-content;
  height: fit-content;
  color: #8b7f8f;
  border-radius: 3px;
  margin-right: 8px;
  margin: 5px 8px 3px 0px;
  border-radius: 5px;
}
.add-tags {
  display: flex;
  flex-wrap: wrap;
}
.save-div {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.multi-select-input {
  margin-top: 20px;
}
.multi-select-input .input-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.input-div .input-label {
  font-size: 13px;
  margin-bottom: 8px;
}
.input-div input {
  height: 38px;
  border: none;
  background-color: #fcf5fe;
  width: 100%;
  border-radius: 23px;
  padding: 0px 18px;
  margin-bottom: 14px;
}
.input-div input,
input::placeholder {
  font-size: 14px;
}
.multi-select-input .tags-list {
  display: flex;
  flex-wrap: wrap;
}
.tag-edit-span {
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  padding: 5px 10px;

  font-size: 13px;
  border-radius: 3px;
  line-height: 1;
}
.tag-edit-span span:last-child {
  cursor: pointer;
}
/* .orgevents-search-count .tag-edit-span {
  margin-bottom: ;
} */
.tags-list .regular-tag {
  border: 1px solid #d9d9d9;
  color: #8b7f8f;
}
.tags-list .album-tag {
  border: none;
  background-color: #fcf5fe;
  color: #ff0072;
}
.save-div .cancel-btn {
  font-weight: 700;
  color: #8b7f8f;
  text-transform: uppercase;
  margin-right: 17px;
  font-size: 13px;
  letter-spacing: 0.06em;
}
.save-div .save-btn {
  font-size: 13px !important;
  padding: 6px 15px !important;
}
@media screen and (max-width: 599px) {
  .album-tag-modal-dialog-content {
    width: 90%;
  }
  .social-gallery-links {
    display: block;
  }
  .ghost-btn {
    height: auto;
    margin-bottom: 15px;
    display: inline-block;
  }
}
