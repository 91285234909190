
.egm-view-cart-btn {
  border-radius: 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  svg {
    width: 16px;
    height: 13px;
    margin-right: 3px;
  }
}
.cta-label-divider {
  opacity: 0.5;
}
