@media (max-width: 991px) {
  .geo.wrap .dv-maincontent-wrap {
    background: #f3f3f3;
  }
}

.egm-eventsfilter {
  margin: 0 -15px;
  padding: 0;
  min-height: 85px;
}
.egm-filter-container {
  background: #fff;
  padding: 20px 15px 10px;

  overflow-x: scroll;
  overflow-y: hidden;
}
.egm-filter-container.egm-has-hscroll {
  background-image: linear-gradient(
    -90deg,
    rgba(0, 0, 0, 0.15),
    rgba(0, 0, 0, 0) 40px
  );
}
.egm-filter-container.stickit {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1030;
  padding: 7px 25px 0px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}
.egm-filter-container.stickit button img {
  height: 18px;
}
.trans-banner-active .egm-filter-container.stickit,
.gtrans-active .egm-filter-container.stickit {
  top: 105px !important;
}
@media (max-width: 600px) {
  .trans-banner-active .egm-filter-container.stickit,
  .gtrans-active .egm-filter-container.stickit {
    top: 120px !important;
  }
}

.egm-filter-title {
  margin-bottom: 10px;
  margin-top: -20px;
  border-top: 1px solid #cfcfcf;
  padding-top: 9px;
}
.egm-filter-title img {
  height: 14px;
  display: inline-block;
  vertical-align: middle;
}
.egm-filter-title span {
  margin-left: 7px;
  font-size: 13px;
  font-weight: 800;
  display: inline-block;
  vertical-align: middle;
  color: #259fa5;
}
.stickit .egm-filter-title {
  display: none;
}

/* this checks if the "Are you a photographer" button is the only child of the parent div, and if it is then hides the br tag so the text is one line. If its not the only child i.e. the customer support email is there, the br won't be hidden - so there is enough space */
.invite-ph-box:only-child br {
  display: none;
}

.egm-filter-container-2 {
  padding: 20px 15px;
}
.egm-filter-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}
.egm-filter-row button {
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* width: 20%; */
}
.egm-has-hscroll .egm-filter-row button:last-child {
  padding-right: 30px;
}
.egm-filter-container button img {
  width: 21px;
  height: 21px;
}
.egm-filter-row button:focus {
  outline: none;
}
.egm-filter-active {
  width: 9px;
  height: 9px;
  position: absolute;
  top: -4px;
  right: 14px;
  z-index: 2;
  background: #259fa5;
  border-radius: 50%;
}
.egm-filter-row button p {
  font-weight: 800;
  font-size: 12.7709px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.16099px;
  text-transform: uppercase;
  color: #8b7f8f;
  margin: 6px 0;
  padding-bottom: 1px;
  border-bottom: 2px solid transparent;
}
.egm-filter-row button.active p {
  color: #30053f;
  border-bottom: 2px solid #30053f;
}
.egm-filter-row-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: transparent;
}
p.egm-filter-count-p {
  font-weight: 800;
  font-size: 20.8978px;
  line-height: 25px;
  color: #1d3557;
  margin-bottom: 0;
}
.egm-filter-clear-btn {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #1d3557;
  margin: 0;
  background: transparent;
  border: none;
  opacity: 0.5;
}
.egm-filter-clear-btn img {
  margin-right: 7px;
}

#filter-dialog,
#linked-events-dialog {
  display: block;
}
#linked-events-dialog .close {
  position: relative;
  top: 26px;
  right: 12px;
}
.egm-nopics-color {
  background-color: #eff5f6;
  padding: 40px;
  border-radius: 10px;
}
.filter-delete-selfie {
  position: absolute;
  right: 8px;
  top: 8px;
  appearance: none;
  border: 0;
  background: rgb(0 0 0 / 30%);
  padding: 8px 10px;
  border-radius: 40px;
}

.filter-dialog-content {
  background: transparent;
  position: relative;
  text-align: center;
  line-height: 1.2;
  width: 78%;
  /* margin: 20% auto 5%; */
  margin: 52px auto;
}
.egm.ns-modal-small .modal-dialog {
  /* margin: 95px auto; */
  margin: 40px auto;
}
.egm.ns .btn {
  padding: 18px 30px !important;
}
.egm.ns .btn.btn-primary {
  box-shadow: 0px 14px 30px rgba(63, 159, 165, 0.25);
}
p.filter-header {
  font-weight: 800;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 1.375px;
  margin: 10px 0;
  color: rgba(255, 255, 255, 0.854053);
}
p.filter-header2 {
  width: 80%;
  font-size: 21px;
  margin: 0 auto 30px;
  line-height: 26px;
  color: #ffffff;
}
.filter-content-div {
  border-radius: 10px;
  background: #fff;
  width: 100%;
  padding: 20px;
}
.geo-selfie__info {
  background: #eff5f6;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 12px;
  text-align: left;
  line-height: 1.4;
  margin-bottom: 20px;
}
.geo-selfie__icon {
  margin-right: 10px;
}
.egm-linked-content {
  max-height: 70vh;
  overflow-y: auto;
}
.filter-content-div .check-radio {
  text-align: left;
}
ul.check-radio {
  padding: 0;
}

ul.check-radio li {
  position: relative;
  margin-bottom: 10px;
}

ul.check-radio label {
  padding-left: 35px;
  font-size: 14px;
}

ul.check-radio input {
  display: none;
}

ul.check-radio .r-dot {
  position: absolute;
  top: 2px;
  left: 0;
  width: 22px;
  height: 22px;
  border: 3px solid transparent;
  background: #e4e4e4;
  border-radius: 18px;
}

ul.check-radio .r-desc {
  margin-top: 4px;
  display: block;
}
.filter-content-div ul.check-radio .r-desc {
  margin-top: 0;
}
ul.check-radio .r-smaller {
  font-size: 11px;
  display: block;
  opacity: 0.5;
}

ul.check-radio input:checked + .r-dot {
  background: #fff;
  box-shadow: 0px 4.4px 16.5px rgba(37, 159, 165, 0.38);
  border: 6px solid #259fa5;
}

ul.check-radio input:checked ~ .r-desc {
  font-weight: 800;
  color: #30053f;
}

ul.check-radio.checkmark input:checked + .r-dot {
  background: url(../images/white-check.svg) #259fa5 no-repeat center;
  box-shadow: 0px 4.4px 16.5px rgba(37, 159, 165, 0.38);
  border: 3px solid transparent;
}

.filter-close-icon {
  /* float: right; */
  position: absolute;
  right: 0;
  cursor: pointer;
  margin: 12px;
}

.selfie-up-btn,
.webcam-component {
  text-wrap: wrap;
}

label.selfie-up-btn img {
  margin-right: 9px;
}
.filter-selfie-img-div {
  width: 194px;
  height: 200px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 2px dashed #30053f;
  border-radius: 10px;
  background: #eff5f6;
}
.filter-selfie-img-div img {
  /* width: 100px;
  height: 100px; */
  object-position: center;
  object-fit: contain;
}
@media (max-width: 370px) {
  .selfie-up-btn,
  .webcam-component {
    font-size: 12px !important;
    text-align: left;
  }
  .selfie-up-btn svg,
  .webcam-component svg {
    width: 15px;
  }
  .filter-selfie-img-div {
    width: 100%;
  }
}

p.filter-selfie-p1,
p.filter-selfie-p2 {
  width: auto;
}
p.filter-selfie-p2 a {
  color: #ff0072;
}
p.filter-cancel-p {
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #8b7f8f;
  opacity: 0.8;
  margin: 20px auto 10px;
}
.filter-action-div {
  display: flex;
  justify-content: space-between;
}
.filter-action-div .geo-btn:first-child {
  padding-left: 0;
  color: #8b7f8f;
}
.egm-slider-wrap {
  padding: 15px;
}
.ns .slider-selection,
.ns .slider-handle {
  background: #fff;
  box-shadow: inset 0 0 0 4px #259fa5;
}

.filter-bib-input {
  width: 171px;
  height: 45px;
  font-size: 18px;
  background: #e4e4e480;
  text-align: center;
  border-radius: 31px;
  margin: 0 auto;
}
.filter-bib-input::placeholder {
  font-size: 18px;
  opacity: 0.5;
}

.filter-more-label {
  text-align: left;
  font-weight: 800;
  font-size: 12.7709px;
  line-height: 16px;
  letter-spacing: 1.16099px;
  text-transform: uppercase;
  color: #8b7f8f;
  margin-bottom: 15px;
  display: block;
}
.trans-banner-active .egm.ns-modal-small .modal-dialog {
  margin-top: 95px;
}
.top-line {
  border-top: 1px solid #cfcfcf;
  margin-top: 20px;
}
