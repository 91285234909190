.play-icon {
  position: absolute;
  width: 62px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}
.time-stamp {
  position: absolute;
  left: 8px;
  right: auto;
  position: absolute;
  background-color: rgb(0 0 0 / 75%);
  height: fit-content;
  font-size: 11px;
  color: white;
  border-radius: 3px;
  padding: 9px 7px;
  line-height: 0;
  text-align: center;
  font-weight: 600;
  bottom: 8px;
  top: auto;
  z-index: 40;
}
.master-search-popup.ns-modal-large .filter-dialog-content {
  max-width: 600px;
}
.video-js > .vjs-big-play-button {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}
.video-js > .vjs-big-play-button .vjs-icon-placeholder:before {
  line-height: 49px;
}
.video-js .vjs-fullscreen-control {
  display: none;
}
.video-js .vjs-picture-in-picture-control {
  display: none;
}
@media (min-width: 991px) {
  body {
    overflow: visible;
  }
}
